import React, { useEffect, useState, useRef } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdAddIcCall } from "react-icons/md";
import ThemeToggle from "../ThemeChanger/ThemeToggle";
import logoLight from "../../../assets/logo-light.svg";
import logoDark from "../../../assets/logo-dark.svg";

const TopBar = () => {
  const [expanded, setExpanded] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(() => localStorage.getItem('theme') === 'dark');
  const navbarRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleScroll = () => setOffset(window.scrollY);
    const handleResize = () => setIsLargeScreen(window.innerWidth >= 768);

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    handleScroll();
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const navbarHeight = navbarRef.current ? navbarRef.current.offsetHeight : 0;
    document.body.style.paddingTop = `${navbarHeight}px`;
  }, [offset]);

  const toggleTheme = () => {
    const newTheme = !isDarkTheme;
    setIsDarkTheme(newTheme);
    document.body.classList.toggle("dark-theme", newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  const logoSrc = isDarkTheme ? logoDark : logoLight;

  return (
    <Navbar
      ref={navbarRef}
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      expand="lg"
      className={`fixed-top ${offset > 5 ? "navbarCustom" : "navbarCustom1"}`}
      style={{ transition: "all 0.3s ease-out" }}
    >
      <div className="navbar-container">
        <ThemeToggle isDarkTheme={isDarkTheme} toggleTheme={toggleTheme} />
        <a href="/">
          <img
            onClick={() => setExpanded(false)}
            src={logoSrc}
            alt="Rullan logo"
            className="logo"
            width="105"
            height="105"
            loading="lazy"
          />
        </a>
      </div>

      <div
        className="custom-toggler"
        onClick={() => setExpanded(!expanded)}
        aria-controls="basic-navbar-nav"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="52"
          viewBox="0 0 24 24"
        >
          <path
            className="menu-toggle"
            d="M4.5 17q-.213 0-.356-.144Q4 16.712 4 16.5t.144-.356Q4.288 16 4.5 16h10.423q.213 0 .356.144t.144.357q0 .212-.144.356q-.143.143-.356.143zm14.438-1.258l-3.196-3.177Q15.5 12.323 15.5 12q0-.323.242-.565l3.196-3.158q.14-.14.345-.15q.204-.01.363.15q.16.16.16.354q0 .194-.16.354L16.573 12l3.073 3.035q.16.134.16.34t-.16.367q-.134.14-.34.14t-.368-.14M4.5 12.5q-.213 0-.356-.144Q4 12.212 4 12t.144-.356q.144-.143.356-.143h7.577q.212 0 .356.144t.144.357t-.144.356q-.144.143-.356.143zm0-4.5q-.213 0-.356-.144T4 7.499q0-.212.144-.356Q4.288 7 4.5 7h10.423q.213 0 .356.144q.144.144.144.357q0 .212-.144.356q-.143.143-.356.143z"
          />
        </svg>
      </div>

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="custom-nav-ul ms-auto mr-5">
          <Nav.Link
            alt="Rullan theme Home"
            as={Link}
            className="custom-nav-a mr-4"
            to="/"
            onClick={() => setExpanded(false)}
          >
            Home
          </Nav.Link>
          <Nav.Link
            alt="Rullan theme About"
            as={Link}
            className="custom-nav-a mr-4"
            to="/about"
            onClick={() => setExpanded(false)}
          >
            About
          </Nav.Link>
          <Nav.Link
            alt="Rullan theme Services"
            as={Link}
            className="custom-nav-a mr-4"
            to="/services"
            onClick={() => setExpanded(false)}
          >
            Services
          </Nav.Link>
          <Nav.Link
            alt="Rullan Blog"
            as={Link}
            className="custom-nav-a  mr-4"
            to="/blog"
            onClick={() => setExpanded(false)}
          >
            Blog
          </Nav.Link>
          <Nav.Link
            alt="Rullan theme Projects"
            as={Link}
            className="custom-nav-a mr-4"
            to="/projects"
            onClick={() => setExpanded(false)}
          >
            Gallery
          </Nav.Link>
    
          <Nav.Link
            alt="Rullan theme Contact"
            as={Link}
            className="custom-nav-a  mr-4"
            to="/contact"
            onClick={() => setExpanded(false)}
          >
            Offer
          </Nav.Link>
        </Nav>
        {!isLargeScreen && (
          <a aria-label="phone Rullan Malningar" href="tel:+3546974563">
            <MdAddIcCall size={32} className="headercall text-dangen " />
          </a>
        )}
        {isLargeScreen && offset > 50 && (
          <a aria-label="Email Rullan Malningar" href="tel:+3546974563">
            <MdAddIcCall size={35} className="headercall text-dangen" />
          </a>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopBar;
