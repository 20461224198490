import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import { db } from "../../../firebase.config"; // Adjust path if needed

import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Button,
  IconButton
} from "@mui/material";

// MUI Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LaunchIcon from "@mui/icons-material/Launch";

const Blog = () => {
  // Check for "slug" param in the URL
  const { slug } = useParams();

  // If we have a slug, show the details view; otherwise, show the list
  return slug ? <BlogDetails slug={slug} /> : <BlogList />;
};

export default Blog;

/* -----------------------------------------------
   BlogList Component
----------------------------------------------- */
const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const snapshot = await db.collection("blogs").get();
        const fetched = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(fetched);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  if (loading) {
    return (
      <Box textAlign="center" mt={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Blog
      </Typography>
      <Grid container spacing={3}>
        {blogs.map((blog) => (
          <Grid item xs={12} sm={6} md={4} key={blog.id}>
            
            {blog.slug && (
                  <Link to={`/blog/${blog.slug}`} style={{ color: "#1976d2" }}>
            
            <AspectRatioMedia
              coverimage={blog.coverimage}
              images={blog.images}
              title={blog.Title}
            />    </Link>
                )}
            <Card sx={{ borderRadius: 0, boxShadow: "none" }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {blog.Title}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {blog.shortdescription}
                </Typography>

                {/* Example of showing additional metadata */}
                <Typography variant="caption" display="block" color="text.secondary">
                  {`Author: ${blog.author || "N/A"}`}
                </Typography>
                <Typography variant="caption" display="block" color="text.secondary">
                  {`Category: ${blog.category || "N/A"}`}
                </Typography>

             
                {blog.slug && (
                  <Link to={`/blog/${blog.slug}`} style={{ color: "#1976d2" }}>
                    Read more <LaunchIcon sx={{ fontSize: 16, verticalAlign: "middle" }} />
                  </Link>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

/* -----------------------------------------------
   BlogDetails Component
----------------------------------------------- */
const BlogDetails = ({ slug }) => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch the blog by slug
  useEffect(() => {
    const fetchBlogBySlug = async () => {
      try {
        const snapshot = await db
          .collection("blogs")
          .where("slug", "==", slug)
          .limit(1)
          .get();

        if (!snapshot.empty) {
          const doc = snapshot.docs[0];
          setBlog({ id: doc.id, ...doc.data() });
        } else {
          setBlog(null);
        }
      } catch (error) {
        console.error("Error fetching blog by slug:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogBySlug();
  }, [slug]);

  if (loading) {
    return (
      <Box textAlign="center" mt={5}>
        <CircularProgress />
      </Box>
    );
  }

  if (!blog) {
    return (
      <Box sx={{ maxWidth: 800, margin: "auto", padding: 2, textAlign: "center" }}>
        <Typography variant="h5" gutterBottom>
          Blog post not found!
        </Typography>
        <Link to="/blog" style={{ color: "#1976d2" }}>
          <ArrowBackIosNewIcon fontSize="inherit" /> Back to all blogs
        </Link>
      </Box>
    );
  }

  // Insert random media (images/videos) into the blog HTML, excluding the cover if it matches
  const getContentWithMedia = () => {
    if (!blog.Content) return "";

    // 1) Filter out the cover image if it exists in the array
    const allMedia = (blog.images || []).filter(
      (m) => !(m.type === "image" && m.url === blog.coverimage)
    );

    if (!allMedia.length) {
      // No extra media to insert
      return blog.Content;
    }

    // 2) Split content by "</p>" for naive insertion
    const paragraphs = blog.Content.split("</p>");
    const maxInsertions = Math.min(paragraphs.length, allMedia.length);

    // Shuffle the media array so we insert them randomly
    const shuffledMedia = [...allMedia].sort(() => 0.5 - Math.random());

    for (let i = 0; i < maxInsertions; i++) {
      const randIndex = Math.floor(Math.random() * paragraphs.length);
      const mediaItem = shuffledMedia[i];

      let mediaHTML = "";

      if (mediaItem.type === "image") {
        mediaHTML = `
          <div style="display:flex; justify-content:center; margin: 20px 0;">
            <div style="position:relative; width:100%; max-width:700px; padding-top:56.25%;">
              <img src="${mediaItem.url}" alt="Blog Insert"
                style="position:absolute; top:0; left:0; width:100%; height:100%; object-fit:cover;"
              />
            </div>
          </div>
        `;
      } else if (mediaItem.type === "video") {
        mediaHTML = `
          <div style="display:flex; justify-content:center; margin: 20px 0;">
            <div style="position:relative; width:100%; max-width:700px; padding-top:56.25%;">
              <video
                src="${mediaItem.url}"
                controls
                style="position:absolute; top:0; left:0; width:100%; height:100%; object-fit:cover;"
              >
              </video>
            </div>
          </div>
        `;
      }

      paragraphs[randIndex] = paragraphs[randIndex] + mediaHTML;
    }

    return paragraphs.join("</p>");
  };

  const contentWithRandomMedia = getContentWithMedia();

  /* -----------------------------
     ACTION BUTTONS: share + print
  ----------------------------- */
  const handleShareFacebook = () => {
    const currentURL = window.location.href;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentURL)}`,
      "_blank"
    );
  };

  const handleShareTwitter = () => {
    const currentURL = window.location.href;
    const text = encodeURIComponent(`Check out this blog: ${blog.Title}`);
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentURL)}&text=${text}`,
      "_blank"
    );
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
      {/* Display cover if there's one */}
      {blog.coverimage && (
        <AspectRatioMedia
          coverimage={blog.coverimage}
          images={[]}
          title={blog.Title}
          showFallback={false}
        />
      )}

      {/* Title */}
      <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
        {blog.Title}
      </Typography>

      {/* Publish Date */}
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {blog.publishTime ? new Date(blog.publishTime).toLocaleDateString() : ""}
      </Typography>

      {/* Author, Category */}
      <Typography variant="body2" color="text.secondary" gutterBottom>
        <strong>Author:</strong> {blog.author || "Unknown"}
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        <strong>Category:</strong> {blog.category || "Uncategorized"}
      </Typography>

    
      {/* Short description (optional) */}
      {blog.shortdescription && (
        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
          {blog.shortdescription}
        </Typography>
      )}

      {/* Insert images/video into the content */}
      <Box
        dangerouslySetInnerHTML={{ __html: contentWithRandomMedia }}
        sx={{ mt: 2, lineHeight: 1.7 }}
      />

      {/* Action buttons (share + print + CTA) */}
      <Box sx={{ mt: 4, display: "flex", gap: 2, flexWrap: "wrap" }}>
        <Button
          variant="contained"
          onClick={handleShareFacebook}
          startIcon={<FacebookIcon />}
        >
          Share on Facebook
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleShareTwitter}
          startIcon={<TwitterIcon />}
        >
          Share on Twitter
        </Button>
        <Button
          variant="outlined"
          onClick={handlePrint}
          startIcon={<PrintIcon />}
        >
          Print
        </Button>

        {/* CTA to contact Rúllan */}
        <Button
          variant="contained"
          color="success"
          href="https://rullan.is/contact"
          target="_blank"
        >
          Contact Rúllan Now
        </Button>

 
          {/* Keywords, Tags */}
      <Typography variant="body2" color="text.secondary" gutterBottom sx={{ fontStyle: "italic" }}>
        <strong>Keywords:</strong> {blog.keywords}
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom sx={{ fontStyle: "italic" }}>
        <strong>Hashtags:</strong> {blog.tags}
      </Typography>
       {/* Optional back link */}
        <Link to="/blog" style={{ color: "#1976d2", textDecoration: "none", marginTop: "8px" }}>
          <ArrowBackIosNewIcon fontSize="inherit" /> Back to all blogs
        </Link>

      </Box>
    </Box>
  );
};

/* -----------------------------------------------
   Reusable component: AspectRatioMedia
   - Renders a 16:9 aspect ratio box
   - If coverimage is provided, uses that
   - Otherwise, tries the first image if found
   - or fallback if showFallback is true
----------------------------------------------- */
const AspectRatioMedia = ({
  coverimage,
  images,
  title,
  showFallback = true,
}) => {
  let displayUrl = null;

  if (coverimage) {
    displayUrl = coverimage;
  } else if (images && images[0] && images[0].type === "image") {
    displayUrl = images[0].url;
  }

  if (!displayUrl && !showFallback) {
    return null;
  }

  return (
    <Box sx={{ position: "relative", width: "100%", pt: "56.25%" }}>
      {displayUrl ? (
        <Box
          component="img"
          src={displayUrl}
          alt={title}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        // fallback box if no image
        showFallback && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              bgcolor: "#f0f0f0",
            }}
          />
        )
      )}
    </Box>
  );
};
