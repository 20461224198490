// App.js

import React, { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Shared/Footer/Footer";
import TopBar from "./Components/Shared/TopBar/TopBar";
import Home from "./Pages/Home/Home/Home";
import Blog from "./Pages/Home/Blog/Blog";

const Dashboard = React.lazy(() => import("./Pages/Home/Skyadmin/Skyadmin"));
const Review = React.lazy(() => import("./Pages/Dashboard/Review/Review"));
const About = React.lazy(() => import("./Pages/Home/About/About"));
const Terms = React.lazy(() => import("./Pages/Home/terms/Terms"));
const Conditions = React.lazy(() => import("./Pages/Home/privacy/Privacy"));
const Contact = React.lazy(() => import("./Pages/Home/Contact/Contact"));
const Services = React.lazy(() => import("./Pages/Home/PaintingServices/PaintingServices"));
const Projects = React.lazy(() => import("./Pages/Home/Projects/Projects"));
const Login = React.lazy(() => import("./Pages/Login/Login"));


function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Toaster />
      <TopBar />
      <Switch>
        <Route exact path="/" component={Home} />

        <Suspense fallback={<div>Loading...</div>}>
          <Route path="/login" component={Login} />
          <Route path="/about" component={About} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Conditions} />
          <Route path="/services" component={Services} />
          <Route path="/projects" component={Projects} />
          <Route path="/contact" component={Contact} />
          <Route path="/review" component={Review} />
          <Route path="/mourad" component={Dashboard} />

          {/* Blog routes */}
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/:slug" component={Blog} />
        </Suspense>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
